import React, { useEffect, useState } from 'react'
import Header from './Header'
import Heading from './Heading'

const Language = () => {
    const [isOpenSideBar, setIsOpenSideBar] = useState(true)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedHeadingIndex, setSelectedHeadingIndex] = useState(0);
    useEffect(() => {
        fetch('../data/Interviewques/html.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(jsonData => {
                setData(jsonData);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);
    const handleSelectHeading = (index) => {
        setSelectedHeadingIndex(index);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }
    return (
        <div className='tut_header_section'>
            <Header />
            <div className='tut_lang_name'>
                <div className='left_asidebar'>

                    <ul style={{ display: isOpenSideBar ? 'block' : 'none', width: isOpenSideBar ? 245 : 0 }}>
                        <h1>HTML table content</h1>
                        {data?.document?.map((heading, index) => (
                            <li key={index} style={{backgroundColor: selectedHeadingIndex === index ? "#cfcfcf" : "#fff"}} onClick={() => { handleSelectHeading(index) }}>{heading.heading}</li>
                        ))}
                    </ul>
                    <div onClick={() => { setIsOpenSideBar(prevOpenSideBar => !prevOpenSideBar) }} className={isOpenSideBar ? 'openSideBar' : 'closeSideBar'}>

                    </div>
                </div>
                <div className='language_notes'>
                    {/* {data?.document?.map((heading, index) => ( */}
                    <Heading heading={data.document?.[selectedHeadingIndex]} />
                    {/* ))} */}
                </div>
            </div>
        </div>
    )
}

export default Language