import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useLocation } from 'react-router-dom';
import ContentRenderer from './ContentRenderer';
import { getAllByLanguage } from './interviewQuesApi';
import InterViewQuesForm from './InterViewQuesForm';
import { ReactComponent as AddIcon } from '../../assets/svgs/squareAdd.svg'
import { useAuth } from '../AuthProvider/AuthContext';

const InterViewQues = () => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const [isOpenSideBar, setIsOpenSideBar] = useState(true)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedQues, setSelectedQues] = useState(0);
    const pathParts = location.pathname.split('/');
    const pageName = pathParts[2]?.toLowerCase();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const handleIsOpenModal = (value) => {
        console.log(value)
        setIsOpenModal(value)  
    }

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await getAllByLanguage(pageName);
                setData(JSON.parse(response).data || []);
            } catch (error) {
                console.error("error", error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();

    }, [pageName])

    


    const handleSelectQues = (id) => {
        setSelectedQues(id);
    };

    const updateNewItems = (newItem) => {
        handleIsOpenModal(false);
        setData([...data, newItem]);

    }



    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='tut_header_section'>
            <Header />
            <div className='tut_lang_name'>
                <div className='left_asidebar'>

                    <ul style={{ display: isOpenSideBar ? 'block' : 'none', width: isOpenSideBar ? 245 : 0 }}>
                        <h1>{pageName} table content</h1>
                        {data?.map((ques, index) => (
                            <li key={index.toString()} style={{ backgroundColor: selectedQues === ques.id ? "#cfcfcf" : "#fff" }} onClick={() => { handleSelectQues(ques.id) }}>{ques.question}</li>
                        ))}
                    </ul>
                    <div onClick={() => { setIsOpenSideBar(prevOpenSideBar => !prevOpenSideBar) }} className={isOpenSideBar ? 'openSideBar' : 'closeSideBar'}>

                    </div>
                </div>
                <div className='language_notes'>
                    {isAuthenticated && <AddIcon onClick={() => handleIsOpenModal(true)} style={{ backgroundColor: "#00f", borderRadius: 6 }} stroke='#00f' fill='#fff' width={28}  />}
                    
                    
                    
                    {data?.map((ques, index) => (
                        <div key={index} className="tut_lang_topic" style={{ backgroundColor: selectedQues === ques.id ? "#cfcfcf" : "#fff" }} id={ques.id}>

                            <h3 className="tut_lang_topic-title">{ques.question}</h3>

                            <ContentRenderer content={ques.answers} />
                        </div>

                    ))}
                    {isAuthenticated && <InterViewQuesForm isOpenModal={isOpenModal} handleIsOpenModal={handleIsOpenModal} language={pageName} updateNewItems={updateNewItems} />}
                    
                </div>
            </div>
        </div>
    )
}

export default InterViewQues