
import './App.css';
import AboutMe from './components/AboutMe/AboutMe';
import Contact from './components/Contact/Contact';
import CoverLetter from './components/CoverLetter/CoverLetter';
import Experiance from './components/Experiance/Experiance';
import Footers from './components/Footers/Footers';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Resume from './components/Resume/Resume';
import Services from './components/Services/Services';
import Skills from './components/Skills/Skills';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Tutorials from './components/Tutorials/Tutorials';
import Language from './components/Tutorials/Language';
import Editor from './components/Editor/Editor';
import InterViewQues from './components/InterViewQues/InterViewQues';
import InterViewQuesTopic from './components/InterViewQues/InterViewQuesTopic';
import { useEffect } from 'react';
import Resume2 from './components/Resume/Resume2';
import { AuthProvider } from './components/AuthProvider/AuthContext';

function App() {

  const RoutingPath = [
    {
      path: "/resume",
      component: <Resume />
    },
    {
      path: "/resume2",
      component: <Resume2 />
    },
    {
      path: "/cover-letter",
      component: <CoverLetter />
    }, {
      path: "/tutorials",
      component: <Tutorials />
    },
    {
      path: "/tutorials/*",
      component: <Language />
    },
    {
      path: "/interview-questions",
      component: <InterViewQuesTopic />
    },
    {
      path: "/interview-questions/*",
      component: <InterViewQues />
    },
    {
      path: "/editor",
      component: <Editor />
    }
  ]

  useEffect(()=>{
    
  },[])
  return (
    <AuthProvider>
    <Router>

      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Header />
              <AboutMe />
              <Skills />
              <Experiance />
              <Contact />
              <Footers />
            </div>
          }
        ></Route>
        {RoutingPath.map((path => {
          return (
            <Route
            key={path.path}
              path={path.path}
              element={path.component}
            ></Route>
          )
        }))}
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
