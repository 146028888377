import React from 'react'
import './Resume.css'
import { ReactComponent as WebsiteSvg } from '../../assets/svgs/website.svg'
import { ReactComponent as MailSvg } from '../../assets/svgs/email-outlined.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'
import { ReactComponent as LocationSvg } from '../../assets/svgs/location.svg'
import { ReactComponent as LinkedInSvg } from '../../assets/svgs/linkedin-outlined.svg'
import { ReactComponent as GithubSvg } from '../../assets/svgs/github.svg'
const Resume = () => {
    const handlePress = () => {
        window.print({ title: "Amit's_resume.pdf" });
    }
    return (
        <div className='resume_body'>
            <div className="resume print-content" media="print">
                <header className='resume_header'>
                    <div className='resume_header--left'>
                        <h1 className='resume_header--heading'>AMIT KUMAR</h1>
                        <p>Experienced Full Stack Developer proficient in React.js and Node.js, with foundational knowledge of React Native. Skilled in creating scalable web and mobile applications with a focus on user experience and client satisfaction. Led successful cross-platform app launches, implementing performance optimizations that boosted engagement and efficiency.</p>
                    </div>
                    <div class="resume_header--contact-info">
                        <span><a href="https://www.devamit.in" target="_blank">devamit.in</a> <WebsiteSvg width={18} height={18} stroke={"#305072"} /></span>
                        <span><a href="mailto:amit115017@gmail.com">amit115017@gmail.com</a> <MailSvg width={18} height={18} stroke={"#305072"} /></span>
                        <span><a href='tel:+91 9852972838' target="_blank" >+91 9852972838</a> <PhoneSvg width={18} height={18} stroke={"#305072"} /></span>
                        <span>Noida, India <LocationSvg width={18} height={18} stroke={"#305072"} /></span>
                        <span><a href='https://www.linkedin.com/in/amit2197' target="_blank">@amit2197</a> <LinkedInSvg width={22} height={22} fill={"#305072"} /></span>
                        <span><a href='https://github.com/amit2197' target="_blank" >@amit2197</a> <GithubSvg width={18} height={18} /></span>
                    </div>

                </header>
                <section class="resume_education">
                    <h2>EDUCATION</h2>
                    <div class="resume_education--item">
                        <div>
                            <h3>Dr. B.C. Roy Engineering College <a href="https://bcrec.ac.in/" target='_blank'></a></h3>
                            <p>Bachelor of Technology (B.Tech) in Computer Science</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <p>Durgapur, India</p>
                            <p><strong>July 2015 - February 2020</strong></p>
                        </div>


                    </div>

                </section>
                <section class="resume_skills">
                    <h2>SKILLS SUMMARY</h2>
                    <ul>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Languages:</strong> Python3, JavaScript, HTML5, CSS3</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Frameworks:</strong> NodeJs, ReactJs, ExpressJs, React Native, Flask, Socket.IO</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Databases:</strong> MySQL, MongoDB, DynamoDB, PostgreSQL, BigQuery</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Cloud & Tools:</strong> AWS, Kafka, GitHub, Visual Studio Code</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Soft Skills:</strong> Problem-Solving, Time Management </li>
                    </ul>
                </section>
                <section class="resume_experience">
                    <h2>WORK EXPERIENCE</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Senior Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>

                            <ul>
                                <li>Designed and optimized MySQL and DynamoDB databases, ensuring data consistency and scalability.</li>
                                <li>Developed APIs using Node.js with Kafka-based chat systems, enhancing real-time communication for users.</li>
                                <li>Integrated payment gateways (Razorpay, Google Play) for secure in-app purchases.</li>
                                <li>Led full-stack development projects across server-side, Android, and web platforms, from UI design to deployment.</li>
                                <li>Implemented AI-driven features, including avatar generation and real-time messaging</li>
                            </ul>
                        </div>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 21 - July 22</strong></p>
                            </div>

                            <ul>
                                <li>Redesigned the company’s website, improving page load speed by 40% and enhancing user experience.</li>
                                <li>Developed RESTful APIs using Node.js, Express.js, and MongoDB, addressing bugs and deploying new features.</li>
                                <li>Automated repetitive tasks using Python scripts, reducing manual workload</li>
                                <li>Utilized BigQuery to design custom queries and visualizations, enabling data-driven decision-making.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="resume_projects">
                    <h2>PROJECTS</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>Portfolio Website</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://devamit.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@devamit.in</a></p>
                                </div>
                                <p><strong>2024</strong></p>
                            </div>
                            <ul>
                                <li>Built using modern web technologies (HTML5, CSS3, JavaScript) to showcase skills, experience, and projects.</li>
                                <li>Focused on responsive design, fast load times, and user-friendly navigation.</li>
                            </ul>
                        </div>
                        <div className="page-break"></div>
                        <div class="page-breake-space"></div>
                        
                        
                        <div class="resume_project--item" break={true}>
                            <div>
                                <div>
                                    <h3>Phnk: Comprehensive App, Website, and Server Development </h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>
                            <ul>
                                <li>Integrated Kafka-based chat functionality, enabling seamless communication among users in real-time.</li>
                                <li>Implemented payment gateway integration with Razorpay and Google Play, streamlining the in-app purchase process.</li>
                                <li>Spearheaded the development of AI avatar generation and chat features, enhancing user interaction and satisfaction.</li>
                                <li>Played a pivotal role in designing and architecting the platform, ensuring scalability and performance.</li>
                            </ul>
                        </div>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>AMRITPEX 2023: Comprehensive App, Website, and Server Development</h3>
                                    {/* <p style={{ fontSize: 13, color: "#333333" }}> @Phnk India Pvt. Ltd | <a href="#">LINK</a></p> */}
                                </div>
                                <p><strong>January 23- March 23</strong></p>
                            </div>
                            <ul>
                                <li>Led the development of a full-featured Android app with user signup/login, activity feed, gallery, map directions, and QR code generation.</li>
                                <li>Designed and implemented a secure backend server using Node.js.</li>
                                <li>Designed and implemented a scalable database schema to store user data, activity information, image references.</li>
                            </ul>
                        </div>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>Tweencraft: Comprehensive Website and Server Development</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 21- July 22</strong></p>
                            </div>
                            <ul>
                                <li>Directed the redesign of the website from scratch, prioritizing user experience and visual appeal.</li>
                                <li>Developed and managed RESTful APIs using Node.js, Express.js, and MongoDB to ensure smooth data flow and enable new features.</li>
                                <li>Created automation scripts in Python to streamline operations and resolve issues efficiently.</li>
                                <li>Implemented real-time messaging systems to boost user engagement and interaction.</li>
                                <li>Utilized BigQuery for data analysis, enabling informed decision-making through insightful reports and graphs.</li>
                            </ul>
                        </div>
                    </div>

                </section>
                <section class="resume_certificates">
                    <h2>CERTIFICATES</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_certificate--item">
                            <div>
                                <div>
                                    <h3>Full Stack Web Developer Nano-degree (<a href="https://udacity.com" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Udacity</a>)</h3>

                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>
                            <p style={{ fontSize: 14, fontWeight: "bold", color: "#333333", textDecoration: "underline" }}>Key Learnings and Achievements:</p>
                            <ul>
                                <li>Developed responsive web applications using HTML, CSS, and JavaScript.</li>
                                <li>Created RESTful APIs with Flask and implemented user authentication.</li>
                                <li>Deployed projects on AWS and Heroku, collaborating via Git/GitHub.</li>
                                <li style={{textDecoration: "none", gap: 10}}>certificates: 
                                    <a href="https://bit.ly/3UW4PwI" style={{marginLeft: 5, marginRight: 10}} target="_blank" rel="noopener noreferrer">bit.ly/3UW4PwI</a>
                                    <a href="https://bit.ly/3AI2779" target="_blank" rel="noopener noreferrer">bit.ly/3AI2779</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>

            <button className='resume-download' onClick={() => {
                document.title = 'My new title';
                handlePress()
            }}>Print</button>
        </div>
    )
}
export default Resume