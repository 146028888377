import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';
import { useAuth } from '../AuthProvider/AuthContext';

const Header = () => {
    const data = [
        { "id": 1, "category": "Programming Language", "name": "Python" },
        { "id": 2, "category": "Web Development", "name": "HTML" },
        { "id": 3, "category": "Web Development", "name": "CSS" },
        { "id": 4, "category": "Web Development", "name": "NodeJs" },
        { "id": 5, "category": "Web Development", "name": "ReactJs" },
        { "id": 6, "category": "Web Development", "name": "Flask" },
        { "id": 7, "category": "Web Development", "name": "Javascript" },
        { "id": 8, "category": "Web Development", "name": "JQuery" },
        { "id": 9, "category": "DevOps", "name": "Git" },
        { "id": 10, "category": "DevOps", "name": "Aws" },
        { "id": 11, "category": "DevOps", "name": "Docker" },
        { "id": 12, "category": "DevOps", "name": "Ubuntu" },
        { "id": 13, "category": "Databases", "name": "MySQL" },
        { "id": 14, "category": "Databases", "name": "SQLite" },
        { "id": 15, "category": "Databases", "name": "MongoDB" },
        { "id": 16, "category": "Databases", "name": "DynamoDB" },
        { "id": 17, "category": "Databases", "name": "PostgreSQL" },
    ]
    const { login, logout, isAuthenticated } = useAuth();
    const [selectedCategory, setSelectedCategory] = useState("Programming Language");
    const [filteredValue, setFilteredValue] = useState(null)
    const [isOpenCategory, setIsOpenCategory] = useState(false)
    const categories = [...new Set(data.map(item => item.category))];
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const updateOpenCategory = () => {
        setIsOpenCategory(prevOpenCategory => !prevOpenCategory);
    };

    const handleTutClick = (name) => {
        const appUrl = `/interview-questions/${name.toLowerCase()}`
        const link = document.createElement('a');
        link.href = appUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await login(username, password);
        if (!success) {
            setError("Invalid credentials!");
        }
        setIsLoginPopupOpen(false);
    };
    return (
        <>
            <div className='tut_header_section'>

                <div className='tut_header'>
                    <a href="/interview-questions"><Logo className="nav-bar__logo" fill="#fbfcf6" /></a>
                    <div className='tut_header--right'>
                        <form action="" className='tut_header--form'>
                            <input type="search" name="search" id="" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </form>
                        <div className={`tut_categories ${isOpenCategory ? 'active' : null}`} onClick={() => { updateOpenCategory() }}>Category</div>
                    </div>
                    {isAuthenticated ? <div style={{ color: "#fff" }} onClick={logout}>logout</div> :
                        <div style={{ color: "#fff" }} onClick={() => setIsLoginPopupOpen(true)}>login</div>}
                </div>



                <div className={`tut_categories--menu ${isOpenCategory ? 'display_tut_categories--menu' : null}`}>
                    <ul className="tut_categories--list">
                        {categories?.map((category, index) => (
                            <li
                                key={index.toString()}
                                className={selectedCategory === category ? "active" : null}
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category}
                            </li>
                        ))}
                    </ul>
                    <ul className="tut_categories--sublist">
                        {data
                            .filter(item => item.category === selectedCategory)
                            .map(item => (
                                <li key={item.id} onClick={() => { handleTutClick(item.name) }}>
                                    {item.name}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            {isLoginPopupOpen &&
                <div className="login-popup">
                    <div className="login-popup-content">
                        <span className="close" onClick={() => setIsLoginPopupOpen(false)}>&times;</span>
                        <form action="" onSubmit={handleSubmit}>
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                name="username" required />
                            <label htmlFor="password">Password</label>
                            <input type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} required />
                            <button type="submit">Login</button>
                        </form>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                </div>}
        </>


    )
}

export default Header