import axios from 'axios';

// API endpoint
const BASE_URL = 'https://4qvr2z49a9.execute-api.us-east-1.amazonaws.com/default/interv_ques';

// Generic API call handler
const apiCall = async (operation, data = {}) => {
    try {
        const response = await axios.post(BASE_URL, {
            operation,
            data,
        });
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('API Error:', error.response?.data || error.message);
        throw new Error(error.response?.data?.error || error.message);
    }
};

// Export functions for each operation
export const addItem = async (item) => apiCall('add', item);
export const updateItem = async (item) => apiCall('update', item);
export const deleteItem = async (id) => apiCall('delete', { id });
export const getItem = async (id) => apiCall('get', { id });
export const getAllByLanguage = async (language) => apiCall('getAllByLanguage', { language });
export const searchByQuestion = async (question) => apiCall('searchByQuestion', { question });
export const getByLanguageAndLevel = async (language, level) =>
    apiCall('getByLanguageAndLevel', { language, level });
