import React from 'react'
import './Resume2.css'
import { ReactComponent as WebsiteSvg } from '../../assets/svgs/website.svg'
import { ReactComponent as MailSvg } from '../../assets/svgs/email-outlined.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'
import { ReactComponent as LocationSvg } from '../../assets/svgs/location.svg'
import { ReactComponent as LinkedInSvg } from '../../assets/svgs/linkedin-outlined.svg'
import { ReactComponent as GithubSvg } from '../../assets/svgs/github.svg'

const Resume2 = () => {
    const handlePress = () => {
        document.title = 'Amit\'s Resume';
        window.print();
    }
    return (
        <div className='resume2--body'>
            <div className='resume2--page print-content' media="print">
                <div className='resume2--header'>
                    <div className='resume2--header-la'>
                        <h1 className='resume2--header-la--name'>Amit Kumar</h1>
                        <p className='resume2--header-la--pos'>Software Engineer</p>
                        <p className='resume2--header-la--desc'>Experienced Full Stack Developer proficient in React.js and Node.js, with foundational knowledge of React Native. Skilled in creating scalable web and mobile applications with a focus on user experience and client satisfaction. Led successful cross-platform app launches, implementing performance optimizations that boosted engagement and efficiency.
                        </p>
                    </div>
                    <div className='resume2--header-ra'>
                        <div><a href="mailto:amit115017@gmail.com">amit115017@gmail.com</a> <MailSvg width={18} height={18} stroke={"#305072"} /></div>
                        <div><a href='tel:+91 9852972838'>+91 9852972838</a> <PhoneSvg width={18} height={18} fill={"#305072"} /></div>
                        <div>New Delhi, India <LocationSvg width={18} height={18} stroke={"#305072"} /></div>
                        <div><a href="https://www.devamit.in" target="_blank" rel="noopener noreferrer">devamit.in</a> <WebsiteSvg width={18} height={18} stroke={"#305072"} /></div>
                        <div><a href='https://www.linkedin.com/in/amit2197' target="_blank" rel="noopener noreferrer">amit2197</a> <LinkedInSvg width={20} height={20} fill={"#305072"} /></div>
                        <div><a href='https://github.com/amit2197' target="_blank" rel="noopener noreferrer">amit2197</a> <GithubSvg width={18} height={18} stroke={"#305072"} /></div>
                    </div>

                </div>
                <hr style={{ position: 'sticky', left: 0, right: 0, borderWidth: 1, borderColor: "#305072", marginBlock: 12 }} />
                <div className='resume2--info'>
                    <div className='resume2--info-ls'>
                        <section className='resume2--info--experiance'>
                            <h2 className='resume2--info--experiance--title'>WORK EXPERIENCE</h2>
                            <div className='resume2--info--experiance--w'>
                                <p className='resume2--info--experiance--wr'>Senior Software Engineer</p>
                                <p className='resume2--info--experiance--wc'>Phnk Networks India Pvt. Ltd</p>
                                <div className='resume2--info--experiance--wt'>
                                    <p>07/2022 - Present</p>
                                    <p>New Delhi, India</p>
                                </div >
                                <ul className='resume2--info--experiance--wd'>
                                    <li>Designed and optimized MySQL and DynamoDB databases, ensuring data consistency and scalability.</li>
                                    <li>Developed APIs using Node.js with Kafka-based chat systems, enhancing real-time communication for users.</li>
                                    <li>Integrated payment gateways (Razorpay, Google Play) for secure in-app purchases.</li>
                                    <li>Led full-stack development projects across server-side, Android, and web platforms, from UI design to deployment.</li>
                                    <li>Implemented AI-driven features, including avatar generation and real-time messaging</li>
                                </ul>
                            </div>
                            <div className='resume2--info--experiance--w'>
                                <p className='resume2--info--experiance--wr'>Software Engineer</p>
                                <p className='resume2--info--experiance--wc'>Kalpkriti Pvt. Ltd</p>
                                <div className='resume2--info--experiance--wt'>
                                    <p>09/2021 - 07/2022</p>
                                    <p>Bengaluru, India</p>
                                </div >
                                <ul className='resume2--info--experiance--wd'>
                                    <li>Redesigned the company’s website, improving page load speed by 40% and enhancing user experience.</li>
                                    <li>Developed RESTful APIs using Node.js, Express.js, and MongoDB, addressing bugs and deploying new features.</li>
                                    <li>Automated repetitive tasks using Python scripts, reducing manual workload</li>
                                    <li>Utilized BigQuery to design custom queries and visualizations, enabling data-driven decision-making.</li>
                                </ul>
                            </div>

                        </section>

                        <section className='resume2--info--eca'>
                            <h2 className='resume2--info--eca--title'>Certifications</h2>
                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>Full Stack Web Developer Nano-degree
                                </p>
                                <p className='resume2--info--eca--sf'>Udacity</p>
                                <div className='resume2--info--eca--st'>
                                    <p>07/2022 - 01/2023</p>
                                    <p>Online</p>
                                </div >
                                <ul className='resume2--info--eca--sd'>
                                    <li>Developed responsive web applications using HTML, CSS, and JavaScript.</li>
                                    <li>Created RESTful APIs with Flask and implemented user authentication.</li>
                                    <li>Deployed projects on AWS and Heroku, collaborating via Git/GitHub.</li>
                                </ul>
                            </div>

                        </section>

                        <section className='resume2--info--edu'>
                            <h2 className='resume2--info--edu--title'>EDUCATION</h2>
                            <div className='resume2--info--edu--s'>
                                <p className='resume2--info--edu--sb'>B.Tech (CSE)</p>
                                <p className='resume2--info--edu--sc'>Dr. B.C. Roy Engineering College</p>
                                <div className='resume2--info--edu--st'>
                                    <p>07/2015 - 02/2020</p>
                                    <p>Durgapur, India</p>
                                </div >
                            </div>

                        </section>


                    </div>
                    <div className='resume2--info-rs'>
                        <section className='resume2--info--skills'>
                            <h2 className='resume2--info--skills--title'>Skills</h2>
                            <ul className='resume2--info--skills--set'>
                                <li>NodeJs</li>
                                <li>MySQL</li>
                                <li>ReactJs</li>
                                <li>ExpressJs</li>
                                <li>Python3</li>
                                <li>Flask</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>JavaScript</li>
                                <li>AWS</li>
                                <li>GitHub</li>
                                <li>BigQuery</li>
                                <li>Kafka</li>
                                <li>MongoDB</li>
                                <li>DynamoDB</li>
                                <li>PostgreSQL</li>
                            </ul>

                        </section>

                        <section className='resume2--info--eca'>
                            <h2 className='resume2--info--eca--title'>Projects</h2>
                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>Portfolio Website
                                </p>
                                <ul className='resume2--info--eca--sd'>
                                    <li>Designed and developed a personal portfolio website: <a href='https://devamit.in'>devamit.in</a>.</li>
                                    <li>Built using modern web technologies (HTML5, CSS3, JavaScript) to showcase skills, experience, and projects.</li>
                                    <li>Focused on responsive design, fast load times, and user-friendly navigation.</li>
                                </ul>
                            </div>

                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>P2P Chat and Group Chat System
                                </p>
                                <ul className='resume2--info--eca--sd'>
                                    <li>Developed a real-time chat system with P2P and group chat functionalities, using Node.js, Kafka, and socket.io</li>
                                    <li>Integrated advanced features like message threading, delivery status, and notifications for an enhanced user experience.</li>
                                    <li>Implemented secure authentication and end-to-end encryption for user privacy.</li>
                                </ul>
                            </div>


                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>Post Management System
                                </p>
                                <ul className='resume2--info--eca--sd'>
                                    <li>Built a post-sharing platform that allows users to create, edit, and share posts, complete with features like likes, comments, and bookmarking.</li>
                                    <li>Designed backend APIs using Express.js and managed data with MySQL for optimal performance and scalability.</li>
                                    <li>Implemented a timeline feature with pagination and dynamic updates for seamless user interaction.</li>
                                </ul>
                            </div>

                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>AMRITPEX2023
                                </p>
                                <ul className='resume2--info--eca--sd'>
                                    <li>Amritpex 2023, India Post's national philatelic exhibition, showcased India's rich postal heritage through stamps, cultural events, and workshops. I developed and hosted the official Android app, featuring a QR code scanner, ticket generator, and interactive event tools, enhancing visitor engagement and convenience.</li>
                                </ul>
                            </div>

                        </section>

                        {/* <section className='resume2--info--eca'>
                            <h2 className='resume2--info--eca--title'>Key Achievements</h2>
                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>Cross-Platform App Launches
                                </p>
                                <ul className='resume2--info--eca--sd'>
                                Led successful cross-platform app launches while implementing performance optimizations that boosted engagement.
                                </ul>
                            </div>


                        </section> */}
                    </div>

                </div>
            </div>

            <button className='resume-download' onClick={() => { handlePress() }}>Print</button>

        </div>
    )
}

export default Resume2