import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

const mockCredentials = {
  username: "Amit2197",
  // This is a hashed version of the password "password"
  passwordHash: "$2a$10$7xTXn/4oBtLVYkczZ2N3e.ywwtRHtrT9Wvl2tgPaQsNPVZJ2VwwDC", //admin2023
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("auth") === "true"
  );

  const login = async (username, password) => {
    const bcrypt = await import("bcryptjs");
    if (
      username === mockCredentials.username &&
      bcrypt.compareSync(password, mockCredentials.passwordHash)
    ) {
      setIsAuthenticated(true);
      localStorage.setItem("auth", "true");
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("auth");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
  };