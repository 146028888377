import React, { useEffect, useState } from 'react'
import "./interViewQues.css"
import { ReactComponent as CloseIcon } from '../../assets/svgs/close.svg'
import { ReactComponent as AddIcon } from '../../assets/svgs/squareAdd.svg'
import { addItem } from './interviewQuesApi';
import { v4 as uuidv4 } from 'uuid';

const InterViewQuesForm = ({ isOpenModal, handleIsOpenModal, language, updateNewItems }) => {
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([{ type: 'text', value: '' }]);

    const handleTypeChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].type = value;
        setAnswers(updatedAnswers);
    };

    const handleValueChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].value = value;
        setAnswers(updatedAnswers);
    };

    const addAnswerField = () => {
        setAnswers([...answers, { type: 'text', value: '' }]);
    };

    const removeAnswerField = (index) => {
        const updatedAnswers = answers.filter((_, i) => i !== index);
        setAnswers(updatedAnswers);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData(e.target);
            const question = formData.get('question');
            const level = formData.get('level');
            const newItem = { id: uuidv4(), question, level, answers, language };
            await addItem(newItem);
            updateNewItems(newItem);

        } catch (error) {
            console.error(error);
            // alert('Error: ' + error.message);
        }
        finally {
            setQuestion('');
            setAnswers([{ type: 'text', value: '' }]);
        }
    };

    return (

        <div className={`popup_modal ${isOpenModal ? 'show_modal' : 'hide_modal'}`}>

            <CloseIcon className="close_icon" stroke="black" onClick={() => {
                handleIsOpenModal(false)
            }} width={48} height={48}
            />

            <h1 className='title'>Interview Questions Form</h1>
            <div>
                <form className='form' onSubmit={handleSubmit}>

                    <label htmlFor="question" >Question</label>
                    <input type="text" id="question" name="question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required />
                    <label htmlFor="level">Level</label>
                    <select id="level" name="level" required>
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                        <option value="expert">Expert</option>
                        <option value="high-level">High-Level</option>
                    </select>
                    <div style={{ flexDirection: "row", alignItems: "center" }}>
                        <label htmlFor="answer">Answer</label> <AddIcon style={{ backgroundColor: "#0f0", borderRadius: 6 }} stroke='#0f0' fill='#fff' width={24} onClick={addAnswerField} />
                    </div>
                    {answers.map((answer, index) => (
                        <div key={index} className="answer-group">
                            <select
                                id={`type-${index}`}
                                name={`type-${index}`}
                                value={answer.type}
                                onChange={(e) => handleTypeChange(index, e.target.value)}
                                required
                            >
                                <option value="text">Text</option>
                                <option value="code">Code</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                            <div>
                                <CloseIcon className="remove_field" width={24} stroke='#fff' onClick={() => removeAnswerField(index)} />
                                {answer.type === 'text' && (
                                    <textarea
                                        id={`value-${index}`}
                                        name={`value-${index}`}
                                        value={answer.value}
                                        onChange={(e) => handleValueChange(index, e.target.value)}
                                        required
                                    >

                                    </textarea>
                                )}
                                {answer.type === 'code' && (
                                    <textarea
                                        id={`value-${index}`}
                                        name={`value-${index}`}
                                        placeholder="Write code here"
                                        value={answer.value}
                                        onChange={(e) => handleValueChange(index, e.target.value)}
                                        required
                                    />
                                )}
                                {answer.type === 'image' && (
                                    <input
                                        type="file"
                                        id={`value-${index}`}
                                        name={`value-${index}`}
                                        accept="image/*"
                                        onChange={(e) => handleValueChange(index, e.target.files[0]?.name || '')}
                                        required
                                    />
                                )}
                                {answer.type === 'video' && (
                                    <input
                                        type="file"
                                        id={`value-${index}`}
                                        name={`value-${index}`}
                                        accept="video/*"
                                        onChange={(e) => handleValueChange(index, e.target.files[0]?.name || '')}
                                        required
                                    />
                                )}
                            </div>


                        </div>
                    ))}


                    <button type="submit">Submit</button>
                    <button type="reset" onClick={() => setAnswers([{ type: 'text', value: '' }])}>
                        Reset
                    </button>


                </form>
            </div>
        </div>
    )
}

export default InterViewQuesForm